import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import {
  withStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  createStyles,
  WithStyles,
  Menu,
  MenuItem
} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { tgrey100, tgreen100, twhite } from '../../styles/colors';
import { AppState, UserLang } from '../../Models/AppState';
import { withRouter, RouteComponentProps } from 'react-router';

const styles = createStyles({
  mainHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '15px 24px',
    backgroundColor: tgrey100
  },
  userWrapper: {
    display: 'flex',
    'align-items': 'center',
    margin: 0,
    'font-weight': 600,
    color: twhite
  },
  userName: {
    margin: '0 1em',
  },
  userLogout: {
    display: 'flex',
    fontSize: '14px',
    lineHeight: '24px',
    cursor: 'pointer',
    '&:hover': {
      color: tgreen100
    }
  },
  exitIcon: {
    marginRight: '5px'
  },
  btnOk: {
    color: tgreen100
  }
});

interface HeaderProps extends WithStyles<typeof styles> { }

interface State {
  anchorEl: HTMLElement | null;
  open: boolean;
  userLang: string;
}

type AllProps = HeaderProps & DispatchProp & RouteComponentProps & MSTP;

class Header extends React.Component<AllProps, State> {
  state: State = {
    open: false,
    anchorEl: null,
    userLang: 'RUS',
  };

  handleClickOpen = () => {
    this.setState({open: true});
  }

  handleClose = () => {
    this.setState({open: false});
  }

  handleLogOut = () => {
    this.handleClose();
    this.props.history.push('/?logout');
  }

  handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseLang = () => {
    this.setState({ anchorEl: null });
  };

  hangleChangeLang = (lang: string) => {
    this.props.dispatch({type: 'SET_USER_LANG', payload: lang});
    this.setState({anchorEl: null});
  }

  render() {
    const { classes, user, history, location, userLang } = this.props;
    const { anchorEl } = this.state;

    return (
      <header className={classes.mainHeader}>
        <img
          src="../img/logo_complitech_white.png"
          height="30"
          alt="Complitech"
          style={{cursor: 'pointer'}}
          onClick={() => window.location.href = 'https://www.licensing.complitech.ru/'}
        />
        {location.pathname !== '/upload' &&
          <Button
            onClick={() => history.push('/upload')}
            variant="contained"
            color="secondary"
            style={{marginLeft: 'auto'}}
          >
            Активировать лицензию
          </Button>
        }

        {location.pathname === '/upload' &&
          <div style={{marginLeft: 'auto'}}>
            <Button
              aria-owns={anchorEl ? 'simple-menu' : undefined}
              aria-haspopup="true"
              onClick={this.handleClick}
              variant="contained"
              color="default"
            >
              {userLang}
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleCloseLang}
            >
              <MenuItem selected={userLang === UserLang.ENG} onClick={() => this.hangleChangeLang('ENG')}>ENG</MenuItem>
              <MenuItem selected={userLang === UserLang.RUS} onClick={() => this.hangleChangeLang('RUS')}>RUS</MenuItem>
            </Menu>
          </div>
        }

        {user &&
          <div className={classes.userWrapper}>
            <p className={classes.userName}>{user ? user.email : ''}</p>
            <div className={classes.userLogout} onClick={this.handleClickOpen}>
              <ExitToAppIcon className={classes.exitIcon} />
              <span>Выйти</span>
            </div>
          </div>
        }

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
        >
          <DialogTitle>Выход</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Вы действительно хотите выйти ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>
              Отмена
            </Button>
            <Button className={classes.btnOk} onClick={this.handleLogOut}>
              Выйти
            </Button>
          </DialogActions>
        </Dialog>
      </header>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  let userLang = state.mainReducer.userLang;
  // const urlParams = new URLSearchParams(window.location.search);
  // if (urlParams.has('lang')) {
  //   let languageFromUrl = urlParams.get('lang');
  //   if (languageFromUrl) {
  //     languageFromUrl = languageFromUrl.toUpperCase();
  //     if (languageFromUrl === UserLang.ENG || languageFromUrl === UserLang.RUS) {
  //       userLang = languageFromUrl;
  //     }
  //   }
  // }
  return {
    user: state.mainReducer.loggedUser,
    userLang: userLang,
  };
};

type MSTP = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(withStyles(styles)(withRouter(Header)));
